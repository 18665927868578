<template>
    <a-layout-content class="Dashboard">
        <a-breadcrumb style="margin: 16px 24px">
            <a-breadcrumb-item>Trang chủ</a-breadcrumb-item>
            <a-breadcrumb-item>Bill</a-breadcrumb-item>
            <a-breadcrumb-item>Danh sách</a-breadcrumb-item>
        </a-breadcrumb>
        <div style="margin-left: 30px; display: flex;">
            <a-select
                showSearch
                placeholder="Chọn salon"
                optionFilterProp="children"
                style="width: 200px"
                :filterOption="filterOption"
                @change="onSalonChange"
                :value="selectedSalonId"
            >
                <a-select-option v-for="salon in getUser.listSalon" :key="salon.id" :value="salon.id">{{ salon.salonName }}</a-select-option>
            </a-select>
            <a-date-picker 
                :disabledDate="disabledDate"
                @change="onDateChange" 
                :value="selectedDate"
            />
            <JsonExcel
                :data="excelData"
                :fields="json_fields"
                worksheet="Bill video"
                :style="{marginRight: '30px', marginLeft: 'auto'}"
            >
                <a-button type="default" style="background-color: #66bb6a; color: white; margin-left: 20px;">Xuất Excel</a-button>
            </JsonExcel>
        </div>
        <div class="page-container" :style="{minHeight: 'calc(100vh - 120px)', backgroundColor: '#fff', margin: '15px 25px', padding: '15px' }">
            <h1>Danh sách bill trong ngày</h1>
            
            <a-table
                :columns="columns"
                :rowKey="bill => bill.billId"
                :dataSource="bills"
                :pagination="false"
                :loading="loading"
            >
                <template slot="checkinVideoUrl" slot-scope="checkinVideoUrl, bill" >
                    <!--<div :set="videoUrl = checkinVideoUrl">-->
                        <div v-if="checkinVideoUrl && checkinVideoUrl != 'DOWNLOADING' && checkinVideoUrl.startsWith('http')">
                            <a-button icon="video-camera" type="primary" @click="watchVideo(checkinVideoUrl)">Xem video</a-button>
                            <!--{{ checkinVideoUrl }}-->
                        </div>
                        <div v-if="!checkinVideoUrl || checkinVideoUrl=='DOWNLOADING'">
                            <a-button icon="download" @click="() => downloadVideo(bill, 'checkin')" :loading="checkinVideoUrl == 'DOWNLOADING'">Download</a-button>
                        </div>
                        <div v-if="checkinVideoUrl && (checkinVideoUrl.startsWith('ERROR') || checkinVideoUrl.startsWith('error'))" :set="error = extractVideoError(checkinVideoUrl)">
                            <a-popover title="Lỗi">
                                <template slot="content">
                                    <div style="max-width: 300px">{{ error }}</div>
                                    <a @click="() => downloadVideo(bill, 'checkin')" slot="content">Thử lại</a>
                                </template>
                                <a-button type="danger">Lỗi</a-button>
                            </a-popover>
                            
                        </div>
                    <!--</div>-->
                    
                </template>

                <template slot="checkoutVideoUrl" slot-scope="checkoutVideoUrl, bill">
                    <!--<div :set="videoUrl = checkoutVideoUrl">-->
                        <div v-if="checkoutVideoUrl && checkoutVideoUrl != 'DOWNLOADING' && checkoutVideoUrl.startsWith('http')">
                            <a-button icon="video-camera" type="primary" @click="watchVideo(checkoutVideoUrl)">Xem video</a-button>
                        </div>
                        <div v-if="!checkoutVideoUrl || checkoutVideoUrl=='DOWNLOADING'">
                            <a-button icon="download" @click="() => downloadVideo(bill, 'checkout')" :loading="checkoutVideoUrl == 'DOWNLOADING'">Download</a-button>
                        </div>
                        <div v-if="checkoutVideoUrl && (checkoutVideoUrl.startsWith('ERROR') || checkoutVideoUrl.startsWith('error'))" :set="error = extractVideoError(checkoutVideoUrl)">
                            <a-popover title="Lỗi">
                                <template slot="content">
                                    <div style="max-width: 300px">{{ error }}</div>
                                    <a @click="() => downloadVideo(bill, 'checkout')" slot="content">Thử lại</a>
                                </template>
                                <a-button type="danger">Lỗi</a-button>
                            </a-popover>
                            
                        </div>
                    <!--</div>-->
                </template>

                <template slot="images" slot-scope="images">
                    <div class="imagesContainer">
                        <div v-for="(imageUrl, i) in getImageUrls(images)" :key="i">
                            <!--<a target="_blank" :href="imageUrl">Link {{i + 1}}</a>-->
                            <a target="_blank" :href="imageUrl"><img v-lazy="imageUrl" class="image" /></a>
                        </div>
                    </div>
                </template>

                <template slot="completeBillTime" slot-scope="completeBillTime">
                    <div>
                        {{ formatHour(completeBillTime) }}
                    </div>
                </template>

                <div slot="videoCheckinTitle">
                    <a-popover>
                        <template slot="content">
                            <a-button @click="() => downloadAll('checkin')">Download tất cả</a-button>
                        </template>
                        
                        <span style="cursor: pointer;">Video checkin</span>
                    </a-popover>
                </div>

                <div slot="videoCheckoutTitle">
                    <a-popover>
                        <template slot="content">
                            <a-button @click="() => downloadAll('checkout')">Download tất cả</a-button>
                        </template>
                        
                        <span style="cursor: pointer;">Video checkout</span>
                    </a-popover>
                </div>
            </a-table>
        </div>
        
    </a-layout-content>
</template>

<script>
    
    import axios from 'axios';
    import moment from 'moment';

    import { mapGetters } from 'vuex';

    import './_BillVideo.scss'
    import { RepositoryFactory } from "@/repository/RepositoryFactory";
    import JsonExcel from 'vue-json-excel'

    const billRepository = RepositoryFactory.get("bill");

    const columns = [
        {
            title: 'Id',
            dataIndex: 'billId',
            width: '10%',
        },
        {
            title: 'Tên khách',
            dataIndex: 'customerName',
            width: '15%',
        },
        {
            title: 'SĐT khách',
            dataIndex: 'customerPhone',
            width: '15%'
        },
        {
            title: 'Thời gian hoàn tất bill',
            dataIndex: 'completeBillTime',
            scopedSlots: { customRender: 'completeBillTime' },
            width: '10%'
        },
        {
            //title: 'Video checkin',
            slots: { title: 'videoCheckinTitle' },
            dataIndex: 'checkinVideoUrl',
            scopedSlots: { customRender: 'checkinVideoUrl' },
        },
        {
            // title: 'Video checkout',
            slots: { title: 'videoCheckoutTitle' },
            dataIndex: 'checkoutVideoUrl',
            scopedSlots: { customRender: 'checkoutVideoUrl' },
        },
        {
            title: 'Ảnh KH',
            dataIndex: 'images',
            scopedSlots: { customRender: 'images' },
            width: '20%'
        }
    ];

export default {
    async mounted() {
        var selectedDate = localStorage.getItem('selectedDate');
        if (selectedDate) {
            selectedDate = moment(selectedDate);
            this.selectedDate = selectedDate;
        }

        var selectedSalonId = localStorage.getItem('selectedSalonId');

        if (selectedSalonId) {
            selectedSalonId = parseInt(selectedSalonId);
            this.selectedSalonId = selectedSalonId;
        }

        await this.fetchBillWithVideos(this.selectedSalonId, this.selectedDate)
    },
    components: {
        JsonExcel
    },
    data() {
        return {
            loading: false,
            columns,
            bills: [],
            selectedSalonId: null,
            selectedDate: moment(),
            json_fields: {
                'Salon': 'salonName',
                'Ngày': 'date',
                'Id bill': 'billId',
                'Tên KH': 'customerName',
                'SĐT KH': 'customerPhone',
                'Video Checkin' : 'checkinVideoUrl',
                'Video Checkout' : 'checkoutVideoUrl',
                'Ảnh KH': 'images'
            },
            json_meta: [
                [
                    {
                        'key': 'charset',
                        'value': 'utf-8'
                    }
                ]
            ]
        };
    },
    methods: {
        formatHour(time) {
            return moment(time).format('HH:mm:ss')
        },
        getImageUrls(images) {
            let imageUrls = [];
            if (images) {
                imageUrls = images.split(',');
            }
            return imageUrls;
        },
        async fetchBillWithVideos(salonId, date) {
            if (!salonId || !date) return;
            this.loading = true;
            const { data: bills } = await billRepository.getBillWithVideos({ salonId: salonId, date: date.format('YYYY-MM-DD') });

            this.bills = bills;
            this.loading = false;
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        async onSalonChange(salonId) {
            this.selectedSalonId = salonId;
            localStorage.setItem('selectedSalonId', salonId);
            await this.fetchBillWithVideos(this.selectedSalonId, this.selectedDate || moment())
        },
        async onDateChange(date) {
            this.selectedDate = date;
            localStorage.setItem('selectedDate', date.format('YYYY-MM-DD'));
            await this.fetchBillWithVideos(this.selectedSalonId, this.selectedDate)
        },
        disabledDate(current) {
            // Can not select days before today and today
            return current && (current < moment().subtract(7, 'days').endOf('day') || current > moment().endOf('day'));
        },
        async downloadVideo(bill, type) {
            var field = type == 'checkin' ? 'checkinVideoUrl' : 'checkoutVideoUrl';
            
            var index = this.bills.findIndex(b => b.billId == bill.billId);
            console.log('index', index)
            if (index != -1) {
                this.bills.splice(index, 1, {
                    ...bill,
                    [field]: 'DOWNLOADING'
                })

                // console.log(this.bills);
            }

            await billRepository.requestDownloadVideo({ billId: bill.billId, type })
        },

        async downloadAll(type) {
            var field = type == 'checkin' ? 'checkinVideoUrl' : 'checkoutVideoUrl';
            const downloadBills = this.bills.filter(b => !(b && b[field] && b[field].startsWith('http')));

            if (!downloadBills.length) return;

            const listRequestItem = downloadBills.map(b => ({
                billId: b.billId,
                type
            }))

            await billRepository.requestDownloadVideoAll(listRequestItem);

            var bills = this.bills.map(b => {
                if (!(b && b[field] && b[field].startsWith('http'))) {
                    return {
                        ...b,
                        [field]: 'DOWNLOADING'
                    }
                }
                return b;
            })

            this.bills = bills;
        },

        watchVideo(videoLink) {
            if (!videoLink.startsWith('http')) videoLink = 'http://' + videoLink;
            window.open(videoLink, '_blank');
        },

        extractVideoError(data) {
            return data.substring(6);
        }
    },
    computed: {
         ...mapGetters('authenticate', [
            'getUser'
        ]),
        excelData() {
            const salon = this.getUser.listSalon.find(s => s.id == this.selectedSalonId);
            
            if (!salon) return [];
            const salonName = salon.salonName;

            const date = this.selectedDate ? this.selectedDate.format('YYYY-MM-DD') : '';
            
            const excelData = this.bills.map(bill => ({
                ...bill,
                salonName,
                date
            }));

            return excelData;
        }
    }
  };
</script>

<style scoped>
    .editable-row-operations {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .imagesContainer {
        display: flex;
        flex-direction: row;
    }

    .image {
        width: 50px;
        height: 50px;
        margin-right: 5px;
        object-fit: cover;
        cursor: pointer;
    }
</style>