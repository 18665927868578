import Repository from "@/repository/Repository";
const resources = "/api/equipment/camera";

export default {

    getSalonCameras({ salonId }) {
        return Repository.get(`${resources}`, {
            /*params: {
                salonId, workDateInt
            }*/
        })
    },
    getCameraById(cameraId) {
        return Repository.get(`${resources}/${cameraId}`);
    },
    updateCamera(id, camera) {
        return Repository.put(`${resources}/${id}`, camera)
    },
    addCamera(camera) {
        return Repository.post(`${resources}`, camera)
    },
    deleteCamera(camera) {
        return Repository.delete(`${resources}/${camera.id}`);
    }
}