
import SalonCameraRepository from "@/repository/SalonCameraRepository";
import BillRepository from "@/repository/BillRepository";

const repositories = {
    salonCamera: SalonCameraRepository,
    bill: BillRepository
}

export const RepositoryFactory = {
    get: name => repositories[name]
}