import { render, staticRenderFns } from "./BillVideo.vue?vue&type=template&id=3e08717d&scoped=true&"
import script from "./BillVideo.vue?vue&type=script&lang=js&"
export * from "./BillVideo.vue?vue&type=script&lang=js&"
import style0 from "./BillVideo.vue?vue&type=style&index=0&id=3e08717d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e08717d",
  null
  
)

export default component.exports