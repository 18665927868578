
import axios from 'axios';
import Repository from "@/repository/Repository";

const resources = "api/bill";


export default {

    getBillWithVideos({ salonId, date }) {
        return axios.get(`${process.env.VUE_APP_API_CAMERA_CONTROL}/${resources}/bill-with-video?salonId=${salonId}&date=${date}`)
    },

    requestDownloadVideo({ billId, type }) {
        return axios.post(`${process.env.VUE_APP_API_CAMERA_CONTROL}/${resources}/request-download-video?billId=${billId}&type=${type}`)
    },

    requestDownloadVideoAll(listRequestItem) {
        return axios.post(`${process.env.VUE_APP_API_CAMERA_CONTROL}/${resources}/request-download-video-all`, listRequestItem)
    }
}